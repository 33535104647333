<template>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col-xl-12 order-xl-1">
        <card>
          <div slot="header" class="row align-items-center">
            <div class="col-8">
              <h3 class="mb-0">{{ $t("PACKAGES.ADD_PACKAGE") }}</h3>
            </div>
            <div class="col-4 text-right">
              <base-button
                @click="goBack"
                type="button"
                class="btn btn-sm btn-primary"
              >
                {{ $t("COMMON.RETURN_TO_LIST") }}
              </base-button>
            </div>
          </div>
          <div class="card-body">
            <package-form
              :loading="loading"
              :packData="pack"
              :formErrors="formErrors"
              @packSubmitted="handleSubmit"
              @formChanged="() => (alertLeave = true)"
            />
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import defaultTaxes from "@/constants/defaultTaxes";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultPackage from "./defaultPackage";
import PackageForm from "./partials/PackageForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    PackageForm,
  },

  mixins: [alertLeave],

  data() {
    const pack = cloneDeep(defaultPackage);
    pack.taxes = cloneDeep(defaultTaxes);
    return {
      pack: pack,
      formErrors: null,
      loading: false,
    };
  },

  created() {},

  methods: {
    goBack() {
      this.$router.push({ name: "List Packages" });
    },

    viewPack(pack) {
      this.alertLeave = false;
      this.$router.push({
        name: "View Package",
        params: { id: pack.id },
      });
    },

    async handleSubmit(pack) {
      this.loading = true;

      const packData = cloneDeep(pack);
      delete packData.id;

      try {
        await this.$store.dispatch("packages/add", packData);
        this.$notify({
          type: "success",
          message: this.$t("PACKAGES.PACKAGE_ADDED"),
        });
        const pack = await this.$store.getters["packages/package"];
        this.viewPack(pack);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        const pack = await this.$store.getters["packages/pack"];
        if (pack) {
          if (pack.id) {
            await this.$store.dispatch("packages/destroy", pack.id);
          }
        }
        this.loading = false;
      }
    },
  },
};
</script>
